<template>
  <section class="section2_1 text-center centerDiv">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="darkblueTxt text-center mb-4">{{ $t('prompt.who_is_this_test_for') }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check checkTxt" style="background: url('images/blue-bg3.png');">
              <input class="form-check-input" type="radio" name="exampleRadios" id="me" value="me"  @click="testingFor('me')">
              <label class="form-check-label" for="me">
                <h6>{{ $t('prompt.for_myself') }}</h6> 
              </label>
            </div>
            <!-- <button class="btn blueBtn" @click="testingFor('me')">Me</button> -->
          </div>
          <div class="col-12">
            <div class="form-check checkTxt" style="background: url('images/purple-bg2.png');">
              <input class="form-check-input" type="radio" name="exampleRadios" id="other" value="other"  @click="testingFor('other')">
              <label class="form-check-label" for="other">
                <h6>{{ $t('prompt.for_other') }}</h6> 
              </label>
            </div>
            <!-- <button class="btn blueBtn" @click="testingFor('other')">Someone else</button> -->
          </div>
        </div>
      </div>
    </section>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    data () {
      return {
        selectedTestFor: null
      }
    },
    computed: {
      ...mapGetters({
        testFor: 'inhaler/getTestingFor',
      }),
    },
    methods: {
      testingFor(testFor) {
        this.selectedTestFor = testFor;
        this.$store.dispatch('inhaler/setTestingFor', testFor);
        setTimeout(() => {
          this.$router.push('/instructions/step-2');
        }, 1000);
      }
    },
    mounted() {
    }
  }
  
</script>